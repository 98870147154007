<template>
<div class="release">
  <van-tabs v-model:active="actives" @click="onChanges" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs">
    <van-tab title="悬赏问答" name="0">
      <van-tabs v-model:active="active"  @click="onChange" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs tabb">
        <van-tab title="发布" name="0">
          <div class="reward-list">
            <div class="reward-list-top">

            </div>
            <van-list
              v-model:loading="bountyloading"
              :finished="bountyfinished"
              :finished-text="communityList.length > 5 ? '没有更多了':''"
              @load="bountyonLoad"
            >
              <bounty :bountyData="communityList" type="fb" @updata="updata()"></bounty>
            </van-list>
          </div>

        </van-tab>
        <van-tab title="草稿" name="1">
          <div class="reward-list">
            <div class="reward-list-top">

            </div>
            <van-list
              v-model:loading="draftloading"
              :finished="draftfinished"
              :finished-text="draftList.length > 5 ? '没有更多了':''"
              @load="draftonLoad"
            >
              <bounty :bountyData="draftList" type="cg" @updata="updata()"></bounty>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="已关闭" name="2">
          <div class="reward-list">
            <div class="reward-list-top">

            </div>
            <van-list
              v-model:loading="xcloseloading"
              :finished="xclosefinished"
              :finished-text="xcloseList.length > 5 ? '没有更多了':''"
              @load="xcloseonLoad"
            >
              <bounty :bountyData="xcloseList" type="gb" @updata="updata()"></bounty>
            </van-list>
          </div>
        </van-tab>
      </van-tabs>

    </van-tab>
    <van-tab title="资源互助" name="1">
      <van-tabs v-model:active="activee"  @click="onChangee" border="false" tab-class="tab-class" nav-class="nav-class" tab-active-class="tab-active-class" class="tabs tabb">
        <van-tab title="发布" name="0">
          <div class="reward-list">
            <div class="reward-list-top">

            </div>
            <van-list
              v-model:loading="bookloading"
              :finished="bookfinished"
              :finished-text="bookList.length > 5 ? '没有更多了':''"
              @load="bookonLoad"
            >
              <resources :electronData="bookList" type="fb" @updata="updata()"></resources>
            </van-list>
          </div>

        </van-tab>
        <van-tab title="草稿" name="1">
          <div class="reward-list">
            <div class="reward-list-top">

            </div>
            <van-list
              v-model:loading="packageloading"
              :finished="packagefinished"
              :finished-text="packageList.length > 5 ? '没有更多了':''"
              @load="packageonLoad"
            >
              <resources :electronData="packageList" type="cg" @updata="updata()"></resources>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="已关闭" name="2">
          <div class="reward-list">
            <div class="reward-list-top">

            </div>
            <van-list
              v-model:loading="zcloseloading"
              :finished="zclosefinished"
              :finished-text="zcloseList.length > 5 ? '没有更多了':''"
              @load="zcloseonLoad"
            >
              <resources :electronData="zcloseList" type="gb" @updata="updata()"></resources>
            </van-list>
          </div>
        </van-tab>
      </van-tabs>
    </van-tab>
  </van-tabs>

</div>

</template>

<script>
import UserInfo from "components/userInfo";
import bounty from "../../../components/bounty"
import resources from "../../../components/resources"
export default {
  name: "user.release",
  data(){
    return{
    active:0,
      actives:0,
      activee:0,
        page:1,
        communityList:[],
        load:true,
        draftList:[],
        draftload:true,
        draftpage:1,
        bountyloading:false,
        bountyfinished:true,
         draftloading:false,
        draftfinished:true,
      xcloseList:[],
      xcloseload:true,
      xclosepage:1,
      xcloseloading:false,
      xclosefinished:true,
      bookpage:1,
      bookList:[],
      bookload:true,
      bookloading:false,
      bookfinished:true,
      packagepage:1,
      packageList:[],
      packageload:true,
      packageloading:false,
      packagefinished:true,
      zcloseList:[],
      zcloseload:true,
      zclosepage:1,
      zcloseloading:false,
      zclosefinished:true,
    }
  },

    created() {
        if (this.$store.getters.acts) {
        this.active = this.$store.getters.acts
          this.activee = this.$store.getters.acts
         this.$store.commit('M_ACTS','1')
      }
      if (this.$store.getters.act == '0') {
        this.actives = '0'
      }else if(this.$store.getters.act == '2'){
        this.actives = '1'
      }
     this.listupdata()
          
  },
  methods:{
    getxcloseList(){
      let url = this.$api.Bounty + '?pageSize=5&page=' + this.xclosepage +  '&status=-1&category_id=0&sort_type=time&is_user=' + this.userInfo.id
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.xcloseList = this.xcloseList.concat(res.data.data)
            this.xclosepage = this.xclosepage + 1
            this.xcloseloading = false;
            this.xclosefinished = false
          }else{
            this.xcloseload = false
            this.xclosefinished = true
          }
        }
      })
    },
    getzcloseList(){
      let url = this.$api.communityElectronUse + '?pageSize=5&page=' + this.zclosepage + '&status=-1'
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.zcloseList = this.zcloseList.concat(res.data.data)
            this.zclosepage = this.zclosepage + 1
            this.zcloseloading = false;
            this.zclosefinished = false
          }else{
            this.zcloseload = false
            this.zclosefinished = true
          }
        }
      })
    },
    getbookList(){
      let url = this.$api.communityElectronUse + '?pageSize=5&page=' + this.bookpage + '&status=1'
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.bookList = this.bookList.concat(res.data.data)
            this.bookpage = this.bookpage + 1
            this.bookloading = false;
            this.bookfinished = false
          }else{
            this.bookload = false
            this.bookfinished = true
          }
        }
      })
    },
    getpackageList(){
      let url = this.$api.communityElectronUse + '?pageSize=5&page=' + this.packagepage + '&status=0'
      this.$http.get(url, true).then(res => {
        if(res.data.success){
          if(res.data.data.length){
            this.packageList = this.packageList.concat(res.data.data)
            this.packagepage = this.packagepage + 1
            this.packageloading = false;
            this.packagefinished = false
          }else{
            this.packageload = false
            this.packagefinished = true
          }
        }
      })
    },
      //发布列表
    getcommunityList(){
        let url = this.$api.Bounty + '?pageSize=5&page=' + this.page + '&status=1&category_id=0&sort_type=time&is_user=' + this.userInfo.id
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              this.communityList = this.communityList.concat(res.data.data)
                this.page = this.page + 1
                this.bountyloading = false;
                this.bountyfinished = false
            }else{
              this.load = false
              this.bountyfinished = true
            }
          }
        })
        },
        //草稿列表
        getdraftList(){
        let url = this.$api.Bounty + '?pageSize=5&page=' + this.draftpage + '&status=0&category_id=0&sort_type=time&is_user=' + this.userInfo.id
        this.$http.get(url, true).then(res => {
          if(res.data.success){
            if(res.data.data.length){
              this.draftList = this.draftList.concat(res.data.data)
                this.draftpage = this.draftpage + 1
                this.draftloading = false
                 this.draftfinished = false

            }else{
              this.draftload = false
              this.draftfinished = true
            }
          }
        })
        },
        listupdata(){
            this.page = 1
        this.communityList = []
        this.load = true
        this.draftList = []
        this.draftload = true
        this.draftpage = 1
          this.xcloseList = []
          this.xcloseload = true
          this.xclosepage = 1
          this.bookpage = 1,
            this.bookList = []
            this.bookload = true
            this.packagepage = 1
            this.packageList = []
            this.packageload = true
          this.zcloseList = []
          this.zcloseload = true
          this.zclosepage = 1
          this.getcommunityList()
          this.getdraftList()
          this.getbookList()
          this.getpackageList()
          this.getxcloseList()
          this.getzcloseList()
        },
    onChange(event) {
        this.active = event
        },
    onChanges(event) {
      this.actives = event
    },
    onChangee(event) {
      this.activee = event
    },
 bountyonLoad(){
        if(this.load){
          this.getcommunityList()
        }
      },
      draftonLoad(){
          if(this.draftload){
          this.getdraftList()
        }
      },
    bookonLoad(){
      if(this.bookload){
        this.getbookList()
      }
    },
    packageonLoad(){
      if(this.packageload){
        this.getpackageList()
      }
    },
    xcloseonLoad(){
      if(this.xcloseload){
        this.getxcloseList()
      }
    },
    zcloseonLoad(){
      if(this.zcloseload){
        this.getzcloseList()
      }
    },
      updata(){
        this.listupdata()
      }
  },
  components:{
    UserInfo,
    bounty,
    resources
  },
   computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~assets/scss/user/release/release.scss";
</style>
<style lang="scss">
$r: 750/16/1rem;
.release{
  .van-tabs__wrap{
    position: fixed;
    width: 100%;
    top: 84/$r;
    z-index: 99;
  }
  .van-tabs__content{
    .van-tabs__wrap{
      position: fixed;
      width: 100%;
      top: 162/$r;
      z-index: 99;
      .van-tabs__line{
      }
    }
  }
  .van-tabs__line {
    width: 0.8533333333rem !important;
    height: 0.128rem;
    background: #FF6B29;
    border-radius: 0.064rem;
    bottom: 20px;
}
  .tabb{
    .van-tabs__line{
      display: none;
    }
    .van-tab--active{
      color: #FF6B29;
    }
  }
} 
</style>